<template>
	<div>
		<SuccessErrorMessage :message="message" />
		<div class="tabs-wrapper">
			<MessageFormTabs
				:tabs="tabs"
			/>
			<NavigatingButtons
				:active_tab_name="active_tab_name"
				@next-tab="nextTab"
				@previous-tab="previousTab"
			/>
		</div>
	</div>
</template>

<script>
import MessageFormTabs from '@/components/message_forms/MessageFormTabs.vue';
import NavigatingButtons from '@/components/message_forms/NavigatingButtons.vue';
import SuccessErrorMessage from "@/components/scenarios/calendar_reports/SuccessErrorMessage.vue";
import { mapGetters, mapActions } from 'vuex';


export default {
	name: 'CreateMessage',
	meta: {
		title: 'Alert Cascade - Create Message'
	},
	components: {
		MessageFormTabs,
		NavigatingButtons,
		SuccessErrorMessage
	},
	data() {
		return {
			customer_group_settings: {},
			show_noticeboard: false,
			active_tab_name: this.$route.name,
		};
	},
	computed: {
		...mapGetters([
			'current_CG',
			'scenario_type',
			'is_session_init_running',
			'message',
			'devices',
			'recipient_types',
			'logged_user'
		]),
		tabs: function () {
			let tabs = [];
			if (this.logged_user.customer_permission != 'SENDER') {
				tabs = tabs.concat(
					{"path_name": "Message Settings", "name": "Settings", "path": "settings" },
					{"path_name": "Message Devices", "name": "Devices", "path": "devices" },
				);
				if (this.scenario_type === 'sequence') {
					tabs.splice(3, 0, {"path_name": "Message Sequence", "name": "Sequence", "path": "sequence" });
				}
			}
			if (this.logged_user.customer_permission != 'SENDER' || (this.logged_user.customer_permission == 'SENDER' && this.current_CG.root_customer_group_permissions.includes('Allow Senders to edit recipients'))) {
				tabs.splice(1, 0, {"path_name": "Message Recipients", "name": "Recipients", "path": "recipients" });
			}
			if (this.logged_user.customer_permission != 'SENDER' || (this.logged_user.customer_permission == 'SENDER' && this.current_CG.root_customer_group_permissions.includes('Allow Senders to edit content'))) {
				tabs.splice(4, 0, {"path_name": "Message Content", "name": "Content", "path": "content" });
			}
			if (this.logged_user.customer_permission != 'SENDER' && this.show_noticeboard) {
				tabs = tabs.concat({"path_name": "Message Noticeboard", "name": "Noticeboard", "path": "noticeboard" });
			}
			if ((this.logged_user.customer_permission == 'ADMIN' || this.logged_user.customer_permission == 'OPERATOR') && this.current_CG.root_customer_group_permissions.includes('Trigger Message')) {
				tabs = tabs.concat({"path_name": "Message Trigger", "name": "Trigger", "path": "trigger" });
			}
			tabs = tabs.concat({"path_name": "Message Finish", "name": "Finish", "path": "finish" });
			return tabs;
		},
	},
	methods: {
		...mapActions([
			'setRecipientTypesAndDevices',
			'getTranslations',
			'resetFields',
			'updateAllDevicesState',
			'updatePeopleList',
			'updateIncludeRecipientOptions',
			'updateIncludeRecipientOptionValue',
		]),
		nextTab() {
			let name = this.$route.name
			let next_index = this.tabs.findIndex(v => v.path_name.indexOf(name) > -1) + 1
			this.active_tab_name = this.tabs[next_index].path_name
			this.$router.push({name: this.tabs[next_index].path_name})
		},
		previousTab() {
			let name = this.$route.name
			let prev_index = this.tabs.findIndex(v => v.path_name.indexOf(name) > -1) - 1
			this.active_tab_name = this.tabs[prev_index].path_name
			this.$router.push({name: this.tabs[prev_index].path_name})
		},
		async createComponent() {
			if (!this.current_CG.customer_group.settings) {
				this.customer_group_settings = {};
			} else {
				this.customer_group_settings = JSON.parse(this.current_CG.customer_group.settings);
			}

			const has_noticeboard_permission = this.current_CG.root_customer_group_permissions.includes('Audio noticeboard')
			const has_noticeboard_numbers = (this.customer_group_settings.noticeboard_numbers && !!this.customer_group_settings.noticeboard_numbers.length);
			this.show_noticeboard = (has_noticeboard_permission && has_noticeboard_numbers);

			await this.setRecipientTypesAndDevices();
			await this.getTranslations('response_button_label,please_press,for,or,please,please_reply,or_press,press');
			this.updateAllDevicesState({ "state": true, "devices_len": this.devices.length });
		},
		getUsersFromUrl() {
			if (!window.location.search){
				return;
			}
			const user_ids_str = window.location.search.split('=')[1]
			return user_ids_str.split(',').map(it => Number(it))
		}
	},
	async created() {
		this.active_tab_name = this.$route.name
		this.resetFields();
		await this.createComponent();
		this.updatePeopleList();
	},
	watch: {
		scenario_type: async function () {
			await this.setRecipientTypesAndDevices();
		},
		$route: {
			deep: true,
			handler: function() {
				this.active_tab_name = this.$route.name
			},
		},
		recipient_types: {
			deep: true,
			handler() {
				let user_ids = this.getUsersFromUrl();
				if(!user_ids) {
					return;
				}
				const individuals = this.recipient_types.filter(it => it.label=='Individuals')[0];
				this.updateIncludeRecipientOptions({"index": 0, "value": individuals});
				const users = individuals.options.filter(it => user_ids.includes(it.id));
				this.updateIncludeRecipientOptionValue({"index": 0, "value": users});
			},
		},
		is_session_init_running: function(){
			if(this.is_session_init_running){
				this.$q.loading.show({})
			}else{
				this.$q.loading.hide()
			}
		}
	},
	destroyed() {
		this.$store.commit('setTimeoutMessage', "");
		this.$store.commit('clearErrors');
		this.resetFields();
	}
}
</script>

<style lang="scss" scoped>
	.tabs-wrapper {
		position: sticky;
		top: 79px;
		background: #fff;
		z-index: 5;
	}
</style>

<style lang="scss">
	.data-wrapper {
		text-align: left;
		border-radius: 0.375rem;
		padding: 1rem;
		margin-bottom: 1rem;
		width: 100%;
		background: $grey-1;
		border: 1px solid $grey-4;
		@media (max-width: 768px) {
			padding: 8px;
			margin-bottom: 0.5rem;
		}
	}
	.padding{
		padding-left: 16px;
		padding-right: 16px;
	}
	.router-view-wrapper {
		padding: 1.5rem 3rem;
		background-color: #fff;
		@media (max-width: 768px) {
			padding: 0px;
		}
	}
</style>
